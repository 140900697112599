import { Box, Button, Typography } from '@mui/material';
import CardWithDivider from 'components/InstaCard';
import { ConfigContext } from 'context';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function CancellationRefundPolicy() {
  const navigate = useNavigate();
  const location = useLocation();
  const config = useContext(ConfigContext);
  //   const url: string = window.location.origin;
  //   const partnerName = getPartnerName(url);

  const handleAgreeAndContinue = () => {
    if (location.state.from) {
      navigate(
        `${location?.state?.from}?acceptedCancellationAndRefundPolicy=true`,
        {
          state: {
            ...location.state
          }
        }
      );
    } else {
      navigate(-1);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <CardWithDivider>
        <Typography variant="h2" paddingX={2} paddingY={4}>
          {config.companyName}&apos;s Refund & Cancellation Policy
        </Typography>
        <Box>
          <Typography variant="h4" fontWeight={500} paddingX={2} paddingY={2}>
            Cancellation:
          </Typography>
          <Typography paddingX={2} paddingY={1} color="text.shadedText">
            During the free trial you may cancel at any time without charges.
          </Typography>
          <Typography paddingX={2} paddingY={1} color="text.shadedText">
            Annual & Monthly plans cancelled will prevent renewal for the
            following billing cycle.
          </Typography>
          <Typography paddingX={2} paddingY={1} color="text.shadedText">
            Cancellation requests must be submitted by calling us at{' '}
            {config.phone} and by sending a written request via email to{' '}
            {config.email} for subscribed users.
          </Typography>
          <Typography variant="h4" fontWeight={500} paddingX={2} paddingY={2}>
            Refunds:
          </Typography>
          <Typography paddingX={2} paddingY={1} color="text.shadedText">
            For annual plans, you are eligible for a 90% refund if you cancel
            within the first 30 days of purchase and your plan will be active
            for 30 days. If you cancel after the initial 30 days of payment, no
            refunds are provided, but your plan will remain active for the full
            year, ensuring uninterrupted service.
          </Typography>
          <Typography
            paddingX={2}
            paddingY={1}
            marginBottom={2}
            color="text.shadedText"
          >
            For monthly plans, you can cancel at any time, and we will stop
            billing you starting from the next billing cycle. There are strictly
            no refunds for the current billing period on Monthly Plans. No
            exceptions are considered for this.
          </Typography>
        </Box>
      </CardWithDivider>
      <Button
        variant="contained"
        sx={{
          width: '100%',
          marginBottom: '16px'
        }}
        onClick={handleAgreeAndContinue}
      >
        Agree & Continue
      </Button>
    </Box>
  );
}

export default CancellationRefundPolicy;
